// src/components/UserTransactionHistory.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Typography, Box, CircularProgress, Card, CardContent, Grid, Divider } from '@mui/material';
import useAuthToken from '../hooks/useAuthToken';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const UserTransactionHistory = () => {
    const token = useAuthToken();
    const [transactions, setTransactions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchTransactions = async () => {
            if (!token) return;

            try {
                const response = await axios.get(`${API_BASE_URL}/stripe/transaction-history/`, {
                    headers: { 'Authorization': `Bearer ${token}` },
                });
                setTransactions(response.data.transactions || []);
                setLoading(false);
            } catch (err) {
                console.error('Error fetching transaction history:', err);
                setError('Unable to load transaction history.');
                setLoading(false);
            }
        };

        fetchTransactions();
    }, [token]);

    if (loading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center">
                <CircularProgress />
            </Box>
        );
    }

    if (error) {
        return (
            <Typography variant="body1" color="error">
                {error}
            </Typography>
        );
    }

    return (
        <Card sx={{ backgroundColor: '#1e1e1e', boxShadow: 3, mt: 3 }}>
            <CardContent>
                <Typography variant="h5" gutterBottom color="primary">
                    Transaction History
                </Typography>
                {transactions.length === 0 ? (
                    <Typography variant="body1" color="textSecondary">
                        No transactions found.
                    </Typography>
                ) : (
                    <Box mt={2}>
                        {/* Header Row */}
                        <Grid container spacing={2} sx={{ borderBottom: '1px solid rgba(255, 255, 255, 0.2)', pb: 1, mb: 1 }}>
                            <Grid item xs={3}><Typography color="textSecondary">Date</Typography></Grid>
                            <Grid item xs={3}><Typography color="textSecondary">Amount</Typography></Grid>
                            <Grid item xs={3}><Typography color="textSecondary">Status</Typography></Grid>
                            <Grid item xs={3}><Typography color="textSecondary">Description</Typography></Grid>
                        </Grid>
                        
                        {/* Transaction Rows */}
                        {transactions.map((transaction, index) => (
                            <Box key={transaction.id}>
                                <Grid container spacing={2} sx={{ py: 1 }}>
                                    <Grid item xs={3}>
                                        <Typography color="primary">
                                            {new Date(transaction.created * 1000).toLocaleDateString()}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Typography color="primary">
                                            ${transaction.amount / 100}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Typography color={transaction.status === 'paid' ? 'success.main' : 'error.main'}>
                                            {transaction.status.charAt(0).toUpperCase() + transaction.status.slice(1)}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Typography color="primary">
                                            {transaction.description || 'N/A'}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                {index < transactions.length - 1 && (
                                    <Divider sx={{ backgroundColor: 'rgba(255, 255, 255, 0.2)' }} />
                                )}
                            </Box>
                        ))}
                    </Box>
                )}
            </CardContent>
        </Card>
    );
};

export default UserTransactionHistory;
