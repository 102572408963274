import React, { useState, useEffect, useRef, useContext } from 'react';
import AuthContext from '../context/AuthContext';
import { Button } from '@mui/material';
import { supabase } from '../supabaseClient';
import { WavRecorder } from '../lib/wavtools/lib/wav_recorder';
import { WavStreamPlayer } from '../lib/wavtools/lib/wav_stream_player';

// const API_BASE_URL = 'https://api-dev.echobach.com/realtime';
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const Talk = () => {
  const { user } = useContext(AuthContext);
  const [recording, setRecording] = useState(false);
  const [webSocket, setWebSocket] = useState(null);
  const [error, setError] = useState('');
  const [textResponse, setTextResponse] = useState('');

  // Map to track processed event_ids (to avoid duplicates)
  const playedEventIds = useRef(new Set());

  const wavRecorder = useRef(null);
  const wavStreamPlayer = useRef(null);
  const isListeningForInterruptions = useRef(false); // To detect when to listen for interruptions

  const base64ToArrayBuffer = (base64) => {
    const binaryString = window.atob(base64);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes.buffer;
  };

  useEffect(() => {
    wavRecorder.current = new WavRecorder({ sampleRate: 24000 });
    wavStreamPlayer.current = new WavStreamPlayer({ sampleRate: 24000 });

    async function requestMicrophoneAccess() {
      try {
        await navigator.mediaDevices.getUserMedia({ audio: true });
      } catch (error) {
        console.error('Failed to get microphone access:', error);
        setError('Failed to access microphone. Please check your settings.');
      }
    }
    requestMicrophoneAccess();

    wavStreamPlayer.current.connect().catch(console.error);

    return () => {
      if (wavStreamPlayer.current) {
        wavStreamPlayer.current.context.close();
      }
      if (wavRecorder.current) {
        wavRecorder.current.quit();
      }
    };
  }, []);

  const handleToggleConnection = async () => {
    if (recording) {
      stopRecording();
    } else {
      startRecording();
    }
  };

  // Send interruption or cancel message to backend
  const sendCancelToServer = async (trackId = null, offset = null) => {
    console.log(`Sending cancel for track: ${trackId} at offset: ${offset}`);

    if (webSocket && webSocket.readyState === WebSocket.OPEN) {
      const cancelMessage = {
        type: 'response.cancel',
        track_id: trackId,
        offset, // The point in the server-side response where the interruption occurred (optional)
      };

      webSocket.send(JSON.stringify(cancelMessage));
      console.log('Sent response.cancel message to server.');
    }
  };

  // Detect speech during playback and send cancel if detected
  const monitorSpeechDuringPlayback = async () => {
    if (!isListeningForInterruptions.current || recording) return; // Ensure we don't monitor twice or while recording

    if (wavRecorder.current.getStatus() === 'recording') {
      console.log('Already recording, not starting another session.');
      return;
    }

    await wavRecorder.current.begin(); // Start monitoring microphone

    wavRecorder.current.record(async (data) => {
      const audioBuffer = data.mono;
      // Detect if any speech is detected (e.g., by checking audio levels)
      const isSpeechDetected = audioBuffer.some(sample => Math.abs(sample) > 1000); // Adjust threshold as necessary

      if (isSpeechDetected) {
        console.log('Speech detected during playback. Sending cancel...');
        await sendCancelToServer(); // Send cancellation immediately upon detecting speech

        // Stop monitoring the microphone after detecting speech
        wavRecorder.current.pause();
        isListeningForInterruptions.current = false; // Stop listening once speech is detected
      }
    });
  };

  const playAudio = async (audioData) => {
    try {
      console.log("Received audio data:", audioData);

      if (wavStreamPlayer.current.context.state === 'suspended') {
        console.log('Resuming AudioContext...');
        await wavStreamPlayer.current.context.resume();
      }

      await new Promise((resolve) => setTimeout(resolve, 100)); // Optional delay

      // Play the audio chunk
      const trackSampleOffset = await wavStreamPlayer.current.add16BitPCM(new Int16Array(audioData), 'track');

      // Start monitoring the microphone for interruptions during playback
      isListeningForInterruptions.current = true;
      monitorSpeechDuringPlayback();

      if (trackSampleOffset?.trackId) {
        const { trackId, offset } = trackSampleOffset;
        await sendCancelToServer(trackId, offset); // Send cancel on user interruption
      }

      console.log("Played audio chunk successfully.");
    } catch (error) {
      console.error('Error playing audio:', error);
    }
  };

  const startRecording = async () => {
    try {
      playedEventIds.current.clear();

      const { data } = await supabase.auth.getSession();
      const token = data.session?.access_token;

      if (!token) {
        throw new Error('User is not authenticated');
      }

      // Define device ID and device type for this WebSocket session
      const deviceId = 'Dashboard';
      const deviceType = 'web';

      // Construct WebSocket URL with device_id and device_type as query parameters
      const wsUrl = `${API_BASE_URL.replace('http', 'ws')}/realtime/ws/conversation/?token=${token}&device_id=${deviceId}&device_type=${deviceType}`;
      const ws = new WebSocket(wsUrl);

      setWebSocket(ws);
      ws.binaryType = 'arraybuffer';

      ws.onopen = () => {
        console.log('WebSocket connection opened.');
        setRecording(true);
      };

      ws.onmessage = async (event) => {
        if (typeof event.data === 'string') {
          const response = JSON.parse(event.data);

          if (response.type === 'response.text.delta') {
            setTextResponse((prev) => prev + response.delta);
          } else if (response.type === 'response.audio.delta') {
            const { event_id, delta } = response;

            if (!playedEventIds.current.has(event_id)) {
              playedEventIds.current.add(event_id);

              const audioArrayBuffer = base64ToArrayBuffer(delta);
              if (audioArrayBuffer.byteLength > 0) {
                await playAudio(audioArrayBuffer);
              } else {
                console.log('Empty or invalid audio data, skipping...');
              }
            }
          }
        }
      };

      ws.onerror = (err) => {
        console.error('WebSocket error:', err);
        setError('WebSocket connection failed.');
      };

      ws.onclose = () => {
        console.log('WebSocket connection closed.');
        setRecording(false);
        setWebSocket(null);
      };

      // Start recording
      await wavRecorder.current.begin();
      wavRecorder.current.record(async (data) => {
        if (ws.readyState === WebSocket.OPEN) {
          const audioEvent = {
            type: 'input_audio_buffer.append',
            audio: btoa(
              new Uint8Array(data.mono).reduce(
                (data, byte) => data + String.fromCharCode(byte),
                ''
              )
            ),
          };
          ws.send(JSON.stringify(audioEvent));
        }
      });
    } catch (error) {
      console.error('Failed to start recording:', error);
      setError(
        'Failed to access microphone. Please ensure it is not being used by another application.'
      );
    }
  };

  const stopRecording = async () => {
    // Send cancel message when user clicks to stop
    await sendCancelToServer();  // Sends the cancellation without specifying trackId/offset (manual stop)

    if (webSocket && webSocket.readyState === WebSocket.OPEN) {
      const commitEvent = {
        type: 'input_audio_buffer.commit',
      };
      webSocket.send(JSON.stringify(commitEvent));
      webSocket.close();
      setWebSocket(null);
    }
    if (wavRecorder.current) {
      wavRecorder.current.pause();
    }
    setRecording(false);
  };

  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        fullWidth
        onClick={handleToggleConnection}
      >
        {recording ? 'Click to Stop' : 'Click to Talk'}
      </Button>

      <div>
        <p>{textResponse}</p>
      </div>

      {error && <p>{error}</p>}
    </div>
  );
};

export default Talk;
