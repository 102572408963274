// src/components/UtilizationData.js
import React, { useState, useEffect } from 'react';
import { Card, CardContent, Typography, Box, CircularProgress } from '@mui/material';
import axios from 'axios';
import { Bar } from 'react-chartjs-2';
import 'chart.js/auto';
import useAuthToken from '../hooks/useAuthToken';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const UtilizationData = () => {
  const token = useAuthToken(); // Retrieve token using the custom hook
  const [utilizationData, setUtilizationData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUtilizationData = async () => {
      if (!token) {
        console.error("No token available");
        setError("Authentication required.");
        setLoading(false);
        return;
      }

      try {
        setLoading(true);
        setError(null);

        // Set start_date to the first day of the current month at 00:00:00 UTC
        const startDate = new Date(new Date().setUTCDate(1));
        startDate.setUTCHours(0, 0, 0, 0);
        const startDateISO = startDate.toISOString();

        // Set end_date to the current date and time in UTC
        const endDate = new Date();
        const endDateISO = endDate.toISOString();

        console.log("Fetching Utilization Data:");
        console.log("Start Date:", startDateISO);
        console.log("End Date:", endDateISO);
        console.log("API Request URL:", `${API_BASE_URL}/realtime/utilization/`);

        const response = await axios.get(`${API_BASE_URL}/realtime/utilization/`, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
          params: {
            start_date: startDateISO,
            end_date: endDateISO,
          },
        });

        console.log("Utilization Data Response:", response.data);
        setUtilizationData(response.data);
      } catch (err) {
        console.error('Error fetching utilization data:', err);
        if (err.response) {
          // Server responded with a status other than 2xx
          if (err.response.status === 404) {
            setError("No utilization data available for the selected date range.");
          } else if (err.response.status === 400) {
            setError("Invalid date format provided.");
          } else {
            setError("An error occurred while fetching utilization data.");
          }
        } else if (err.request) {
          // Request was made but no response received
          setError("No response from server. Please try again later.");
        } else {
          // Something else happened
          setError("An unexpected error occurred.");
        }
      } finally {
        setLoading(false);
      }
    };

    fetchUtilizationData();
  }, [token]);

  // Process utilization data for chart display
  const processUtilizationData = () => {
    if (!utilizationData || utilizationData.length === 0) {
      return { labels: [], datasets: [] };
    }

    const dailyCosts = utilizationData.reduce((acc, log) => {
      const date = new Date(log.timestamp).toLocaleDateString();
      acc[date] = (acc[date] || 0) + log.total_cost_dollars;
      return acc;
    }, {});

    const labels = Object.keys(dailyCosts).sort((a, b) => new Date(a) - new Date(b));
    const data = labels.map(date => dailyCosts[date]);

    const utilizationChartData = {
      labels: labels,
      datasets: [
        {
          label: 'Utilization Costs (USD)',
          data: data,
          backgroundColor: 'rgba(75, 192, 192, 0.6)',
          borderColor: 'rgba(75, 192, 192, 1)',
          borderWidth: 1,
        },
      ],
    };

    const utilizationChartOptions = {
      responsive: true,
      plugins: {
        legend: {
          position: 'top',
        },
        title: {
          display: true,
          text: 'Monthly Utilization Costs',
        },
      },
      scales: {
        y: {
          beginAtZero: true,
          title: {
            display: true,
            text: 'Cost (USD)',
          },
        },
        x: {
          title: {
            display: true,
            text: 'Date',
          },
        },
      },
    };

    return { labels, datasets: utilizationChartData.datasets };
  };

  const { labels, datasets } = processUtilizationData();

  return (
    <Card sx={{ backgroundColor: '#1e1e1e', boxShadow: 3, mb: 4 }}>
      <CardContent>
        <Typography variant="h5" gutterBottom>
          Utilization Data
        </Typography>

        {loading ? (
          <Box display="flex" justifyContent="center" alignItems="center" height="200px">
            <CircularProgress />
          </Box>
        ) : error ? (
          <Typography variant="body2" color="error">
            {error}
          </Typography>
        ) : (
          <>
            <Typography variant="body2" gutterBottom>
              Overview of your device usage over time.
            </Typography>
            {labels.length > 0 ? (
              <Box>
                <Bar data={{ labels, datasets }} options={{
                  responsive: true,
                  plugins: {
                    legend: {
                      position: 'top',
                    },
                    title: {
                      display: true,
                      text: 'Monthly Utilization Costs',
                    },
                  },
                  scales: {
                    y: {
                      beginAtZero: true,
                      title: {
                        display: true,
                        text: 'Cost (USD)',
                      },
                    },
                    x: {
                      title: {
                        display: true,
                        text: 'Date',
                      },
                    },
                  },
                }} />
              </Box>
            ) : (
              <Typography variant="body2">No utilization data available for the selected date range.</Typography>
            )}
          </>
        )}
      </CardContent>
    </Card>
  );
};

export default UtilizationData;
